.body {
  /* background-image: url('./assets/images/background4.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-attachment: fixed;

    color: hsla(0,0%,100%,.87); */
}

.bg-uley {
  background-image: url('./assets/images/background1920х1080.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;

  background-color: #000103 !important;

  --cui-body-color: hsla(0, 0%, 100%, 0.87);
}

/* .dark-theme .h2 {
    color: #ffffff!important;
} */

.my-table {
  width: 100%;
}

.table-head-content {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  height: 40px;
  background: #2b3337;
  z-index: 1;
}

.table-head-content2 {
  position: absolute;
  top: 58px;
  left: 16px;
  width: 55px;
  height: 555px;
  background: #131c21;
  z-index: 0;
}
.table-head-content3 {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 55px;
  height: 40px;
  background: #2b3337;
  z-index: 2;
}

.table-col-content {
  position: absolute;
  top: 60px;
  /* left: 0px; */
  height: 555px;
  background: #131c21;
  z-index: 0;
  width: 51px;
  border-left: 1px solid #242c31;
  border-right: 1px solid #242c31;
}

th.my-th {
  /* text-align: left; */
  background: #2b3337;
  position: sticky;
  top: 0px;
  z-index: 1;
  cursor: pointer;
}

th.myid-th {
  background: #2b3337;
  position: sticky;
  left: 0px;
  top: 0px;
  min-width: 50px;
  z-index: 2;
}

th.myfio-th {
  background: #2b3337;
  position: sticky;
  top: 0px;
  min-width: 250px;
  left: 50px;
  z-index: 2;
}

td.my-td {
  position: sticky;
  left: 0;
  min-width: 40px;
  background-color: #131c21;
}

td.myfio-td {
  position: sticky;
  left: 50px;
  /* min-width: 50px; */
  background-color: #131c21;
}

.scrooll-table {
  overflow-y: auto;
  height: 600px;
  /* border: 1px solid #242c31; */
}

td.widthSpace {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis; /* Добавляем многоточие */
  width: 1%; /* Форсирует сужение ячейки */
}

th.widthSpace {
  white-space: nowrap; /* Текст не переносится */
  width: 1%; /* Форсирует сужение ячейки */
}

th.widthFio {
  /* min-width: 250px;
  max-width: 250px; */
  width: 250px;
}

th.widthPhone {
  min-width: 170px;
}

th.widthTg {
  min-width: 110px;
}

th.widthCity {
  width: 130px;
}

th.widthCompany {
  width: 130px;
}

.fixed-table-container {
  width: 100%;
  height: 200px;
  border: 1px solid #242c31;
  margin: 10px auto;
  background-color: #131c21;
  /* above is decorative or flexible */
  position: relative; /* could be absolute or relative */
  padding-top: 30px; /* height of header */
}

.fixed-table-container-inner {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.header-background {
  background-color: #2b3337;
  height: 30px; /* height of header */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.th-inner {
  position: absolute;
  top: 0;
  line-height: 30px; /* height of header */
  text-align: left;
  border-left: 1px solid black;
  padding-left: 5px;
  margin-left: -5px;
}
.first .th-inner {
  border-left: none;
  padding-left: 6px;
}

.thead {
  --cui-table-bg: #131c21;
  --cui-table-color: hsla(0, 0%, 100%, 0.87);
  --cui-table-border-color: hsla(0, 0%, 100%, 0.177);
  --cui-table-striped-bg: hsla(0, 0%, 100%, 0.139);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: hsla(0, 0%, 100%, 0.177);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: hsla(0, 0%, 100%, 0.158);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
}

.table-dark {
  --cui-table-bg: #131c21;
  --cui-table-border-color: #252d32;
}

.dark-theme .table-light:not([class*='dark:']) {
  --cui-table-bg: hsla(0, 0%, 100%, 0.1);
  --cui-table-color: hsla(0, 0%, 100%, 0.87);
  --cui-table-border-color: hsla(0, 0%, 100%, 0.177);
  --cui-table-striped-bg: hsla(0, 0%, 100%, 0.139);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: hsla(0, 0%, 100%, 0.177);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: hsla(0, 0%, 100%, 0.158);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
}

.header {
  /* --cui-header-bg: #2a2b36; */
  --cui-header-bg: #131c21;
  --cui-header-color: hsla(0, 0%, 100%, 0.75);
  --cui-header-hover-color: hsla(0, 0%, 100%, 0.9);
  --cui-header-active-color: #fff;
  --cui-header-disabled-color: hsla(0, 0%, 100%, 0.25);
  --cui-header-brand-color: #fff;
  --cui-header-brand-hover-color: #fff;
  --cui-header-toggler-color: hsla(0, 0%, 100%, 0.75);
  --cui-header-toggler-hover-color: #fff;
  --cui-header-toggler-icon-bg: url(data:image/svg+xml;charset=utf8,%3Csvg viewBox=%270 0 30 30%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath stroke=%27rgba%28255, 255, 255, 0.75%29%27 stroke-width=%272.25%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3E%3C/svg%3E);
  --cui-header-toggler-hover-icon-bg: url(data:image/svg+xml;charset=utf8,%3Csvg viewBox=%270 0 30 30%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath stroke=%27rgba%28255, 255, 255, 0.9%29%27 stroke-width=%272.25%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3E%3C/svg%3E);
  --cui-header-toggler-border-color: hsla(0, 0%, 100%, 0.1);
  --cui-header-divider-border-color: hsla(0, 0%, 100%, 0.1);
  box-shadow: 0 2px 2px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.14),
    0 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.12),
    0 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.2);
  border-bottom: #000;
}

.text-medium-emphasis {
  /* color: rgba(var(--cui-medium-emphasis-rgb),var(--cui-text-opacity))!important; */
  /* color: #a3a3a3 !important; */
  color: rgb(119, 119, 119) !important;
  line-height: 1;
  margin-bottom: 4px;
}

.dark-theme .input-group-text {
  color: #8f8b8b;
  text-align: center;
  background-color: #1f272c;
  border: 1px solid #283035;
}

.sidebar {
  --cui-sidebar-color: hsla(0, 0%, 100%, 0.87);
  /* --cui-sidebar-bg: #2a2b36; */
  --cui-sidebar-bg: #131c21;
  --cui-sidebar-border-width: 0;
  --cui-sidebar-border-color: transparent;
  --cui-sidebar-brand-color: inherit;
  --cui-sidebar-brand-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-footer-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-header-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-toggler-bg: rgba(0, 0, 21, 0.2);
  --cui-sidebar-toggler-hover-bg: rgba(0, 0, 21, 0.3);
  --cui-sidebar-nav-title-color: hsla(0, 0%, 100%, 0.6);
  --cui-sidebar-nav-link-color: hsla(0, 0%, 100%, 0.6);
  --cui-sidebar-nav-link-bg: transparent;
  --cui-sidebar-nav-link-icon-color: hsla(0, 0%, 100%, 0.6);
  --cui-sidebar-nav-link-hover-color: hsla(0, 0%, 100%, 0.87);
  --cui-sidebar-nav-link-hover-bg: hsla(0, 0%, 100%, 0.05);
  --cui-sidebar-nav-link-hover-icon-color: hsla(0, 0%, 100%, 0.87);
  --cui-sidebar-nav-link-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-sidebar-nav-link-active-bg: hsla(0, 0%, 100%, 0.05);
  --cui-sidebar-nav-link-active-icon-color: hsla(0, 0%, 100%, 0.87);
  --cui-sidebar-nav-link-disabled-color: hsla(0, 0%, 100%, 0.38);
  --cui-sidebar-nav-link-disabled-icon-color: hsla(0, 0%, 100%, 0.6);
  --cui-sidebar-nav-group-bg: rgba(0, 0, 0, 0.2);
  --cui-sidebar-nav-group-toggle-show-color: hsla(0, 0%, 100%, 0.6);
}

.form-control {
  --cui-input-color: hsla(0, 0%, 100%, 0.87);
  --cui-input-bg: hsla(0, 0%, 100%, 0.05);
  --cui-input-border-color: hsla(0, 0%, 100%, 0.09);
  --cui-input-focus-color: hsla(0, 0%, 100%, 0.87);
  --cui-input-focus-bg: hsla(0, 0%, 100%, 0.05);
  --cui-input-focus-border-color: hsla(0, 0%, 100%, 0.2);
  --cui-input-disabled-bg: rgba(0, 0, 21, 0.1);
  --cui-input-placeholder-color: hsla(0, 0%, 100%, 0.6);
  --cui-input-plaintext-color: hsla(0, 0%, 100%, 0.87);
  --cui-form-text-color: hsla(0, 0%, 100%, 0.6);
}

.form-select {
  --cui-form-select-color: hsla(0, 0%, 100%, 0.87);
  --cui-form-select-bg: hsla(0, 0%, 100%, 0.05);
  --cui-form-select-border-color: #ffffff17;
  --cui-form-select-focus-border-color: hsla(0, 0%, 100%, 0.2);
  --cui-form-select-disabled-bg: rgba(0, 0, 21, 0.1);
}

.multi-select {
  --rmsc-primary: #0b182e !important;
  --rmsc-hover: #4b5154 !important;
  --rmsc-selected: #ab989833 !important;
  --rmsc-border: #ffffff17 !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: #1f272c !important;
  --rmsc-spacing: 10px;
  --rmsc-border-radius: 4px;
  --rmsc-height: 38px;
}

.dark-theme {
  --cui-body-bg: #181924;
  /* --cui-body-bg: #000000; */
  --cui-heading-color: #dfdfdf;
  --cui-body-color: hsla(0, 0%, 100%, 0.87);
  --cui-elevation-base-color: 0, 0, 0;
  --cui-high-emphasis: hsla(0, 0%, 100%, 0.87);
  --cui-medium-emphasis: hsla(0, 0%, 100%, 0.6);
  --cui-disabled: hsla(0, 0%, 100%, 0.38);
  --cui-border-color: hsla(0, 0%, 100%, 0.075);
  --cui-link-color: #948dd2;
  --cui-link-hover-color: #a9a4db;
  --cui-bg-primary: #857dcc;
  --cui-bg-secondary: #a7a7a7;
  --cui-bg-success: #519668;
  --cui-bg-info: #5299e0;
  --cui-bg-warning: #d5a439;
  --cui-bg-danger: #c77171;
  --cui-bg-light: hsla(0, 0%, 100%, 0.1);
  --cui-bg-dark: #0000151a;
  --cui-bg-gray: #131c21;
  --cui-text-primary: #857dcc;
  --cui-text-secondary: #a7a7a7;
  --cui-text-success: #519668;
  --cui-text-info: #5299e0;
  --cui-text-warning: #d5a439;
  --cui-text-danger: #c77171;
  --cui-text-light: hsla(0, 0%, 100%, 0.1);
  --cui-text-dark: rgba(0, 0, 21, 0.1);
  --cui-primary: #857dcc;
  --cui-secondary: #a7a7a7;
  --cui-success: #519668;
  --cui-info: #5299e0;
  --cui-warning: #d5a439;
  --cui-danger: #c77171;
  --cui-light: hsla(0, 0%, 100%, 0.1);
  --cui-dark: rgba(0, 0, 21, 0.1);
  /* --cui-primary-rgb: 133,125,204; */
  --cui-primary-rgb: 75, 0, 130;
  --cui-secondary-rgb: 167, 167, 167;
  --cui-success-rgb: 81, 150, 104;
  --cui-info-rgb: 30, 144, 255;
  --cui-warning-rgb: 218, 165, 32;
  --cui-danger-rgb: 180, 0, 0;
  --cui-light-rgb: 255, 255, 255;
  --cui-dark-rgb: 0, 0, 21;
  --cui-body-color-rgb: 255, 255, 255;
  --cui-body-bg-rgb: 24, 25, 36;
  --cui-primary-start: #4638c2;
  --cui-primary-stop: #2d2587;
  --cui-secondary-start: #d1d2d3;
  --cui-secondary-stop: #fff;
  --cui-success-start: #45a164;
  --cui-success-stop: #2e8c47;
  --cui-info-start: #4799eb;
  --cui-info-stop: #4280b4;
  --cui-warning-start: #e1a82d;
  --cui-warning-stop: #dd9124;
  --cui-danger-start: #d16767;
  --cui-danger-stop: #c14f4f;
  --cui-light-start: #e8e8e8;
  --cui-light-stop: #fff;
  --cui-dark-start: #4c4f54;
  --cui-dark-stop: #292a2b;
}

.modal {
  --cui-modal-bg: #1d1f2b;
  --cui-modal-border-color: rgba(0, 0, 0, 0.2);
  --cui-modal-header-border-color: rgba(0, 0, 0, 0.2);
  --cui-modal-footer-border-color: rgba(0, 0, 0, 0.2);
  --cui-modal-color: #ffffff;
}

.dark-theme .form-select > option {
  background-color: #1f272c;
}

.dark-theme .card {
  /* --cui-card-bg: #24252f; */
  --cui-card-bg: #131c21;
  --cui-card-border-color: hsla(0, 0%, 100%, 0.075);
  /* --cui-card-cap-bg: #282933; */
  --cui-card-cap-bg: #131c21;
  --cui-card-cap-color: #ffffff;
}

.dark-theme .card-body {
  --cui-card-color: #ffffff;
}

.dark-theme .progress {
  --cui-progress-bg: hsla(0, 0%, 100%, 0.2);
}

/* .dark-theme .card-header {
    background-color: #131c21;
} */

.dark-theme .nav {
  --cui-nav-link-disabled-color: hsla(0, 0%, 100%, 0.38);
  --cui-nav-pills-link-active-bg: #857dcc;
  --cui-nav-pills-link-active-color: #fff;
  --cui-nav-tabs-border-color: #ffffff00;
  --cui-nav-tabs-link-active-bg: hsla(0, 0%, 100%, 0.075);
  --cui-nav-tabs-link-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-nav-tabs-link-active-border-color: transparent;
  --cui-nav-tabs-link-hover-border-color: hsla(0, 0%, 100%, 0.075) hsla(0, 0%, 100%, 0.075)
    hsla(0, 0%, 100%, 0.075);
  --cui-nav-underline-border-color: hsla(0, 0%, 100%, 0.075);
  --cui-nav-underline-link-color: hsla(0, 0%, 100%, 0.6);
  --cui-nav-underline-link-active-color: #857dcc;
  --cui-nav-underline-link-active-border-color: #857dcc;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--cui-nav-tabs-link-active-color);
  background-color: hsl(201.43deg 26.92% 10.2%);
  border-color: var(--cui-nav-tabs-link-active-border-color);
}

.dark-theme .footer {
  /* --cui-footer-bg: #2a2b36; */
  --cui-footer-bg: #131c21;
  --cui-footer-color: hsla(0, 0%, 100%, 0.87);
  --cui-footer-border-width: 0;
  --cui-footer-border-color: transparent;
}

.bg-danger-gradient {
  background-color: #e55353;
  background-color: var(--cui-danger, #d93737);
  background-image: linear-gradient(45deg, #e55353, #d93737);
  background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #e55353) 0,
    var(--cui-danger-stop, #d93737 100%)
  );
}

.rounded-pill {
  border-radius: 50rem !important;
  border-radius: var(--cui-border-radius-pill) !important;
}

/* input[type="file"] {
    display: none;
} */

.custom-file-upload {
  border: 1px solid #8a93a2;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #8a93a2;
  color: #fff;
  border-radius: 0 5px 5px 0;
}

.input-file-text {
  padding: 0 10px;
  line-height: 40px;
  text-align: left;
  height: 38px;
  display: block;
  float: left;
  box-sizing: border-box;
  width: 235px;
  border-radius: 6px 0px 0 6px;
  border: 1px solid #283035;
  background-color: #1f272c;
}

.box {
  animation: 2.5s show ease;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.box-top {
  /* animation: 3s show2 ease; */
}

@keyframes show2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* chart */
.wrapper-chart {
  font-size: 14px;
  font-family: Helvetica;
  color: #666;
  padding: 50px;
}

.svg {
  overflow: visible;
}

.axis {
  /* color: #ccd6eb;
    &  */
  text {
    color: #fff;
  }
  & .domain {
    display: none;
  }
  &.xAxis {
    & line {
      display: block;
    }
  }
}

/* text-field */
.text-field {
  margin-bottom: 20px;
  position: relative;
}

.text-field__label {
  display: block;
  margin-bottom: 0.25rem;
}

.text-field__input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 5px 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  /* color: #858585; */
  color: #ffffff;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #2d2e38;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.text-field__textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #858585;
  background-color: #1d1f2b;
  background-clip: padding-box;
  border: 1px solid #2d2e38;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.text-field__input::placeholder {
  color: #212529;
  opacity: 0.4;
}

.text-field__input:focus {
  color: #858585;
  background-color: #1d1f2b;
  border-color: #bdbdbd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
}

.text-field__input:disabled,
.text-field__input[readonly] {
  background-color: #131c21;
  opacity: 1;
}

.star-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  gap: 5px;

  width: 124px;
  height: 22px;
}

.star-icon {
  width: 30px;
}

.spec-style {
  background-color: rgb(19, 28, 33);
  /* position: absolute;
    top: 260px;
    left: 291px; */
  border: 1px solid #2d2e38;
  border-radius: 6px;
  width: 320px;
}

.menu-reyting {
  position: relative;
  display: inline-block;
}

.menu-content {
  display: none;
  position: absolute;
  background-color: #131c21;
  min-width: 250px;
  z-index: 10;
  border: 1px solid #2d2e38;
  border-radius: 6px;
}

.menu-content span {
  color: white;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  text-align: left;
}

.menu-content span:hover {
  background-color: #182025;
}

.menu-content {
  display: none;
  position: absolute;
  background-color: #131c21;
  min-width: 250px;
  z-index: 10;
  border: 1px solid #2d2e38;
  border-radius: 6px;
}

.menu-content-block {
  opacity: 0;
  position: absolute;
  top: 10px;
  min-width: 100px;
  left: 130px;
  background-color: #131c21;
  z-index: 10;
  border: 1px solid #2d2e38;
  border-radius: 6px;
}

.menu-content-block:hover {
  opacity: 1;
  position: absolute;
  top: 10px;
  min-width: 100px;
  left: 130px;
  background-color: #131c21;
  z-index: 10;
  border: 1px solid #2d2e38;
  border-radius: 6px;
}

.menu-content-block span {
  color: white;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  text-align: left;
}

.menu-content-krest {
  opacity: 0;
  position: absolute;
  top: 200px;
  min-width: 100px;
  left: 130px;
  background-color: #131c21;
  z-index: 10;
  border: 1px solid #2d2e38;
  border-radius: 6px;
}

.menu-content-krest:hover {
  opacity: 1;
  position: absolute;
  top: 200px;
  min-width: 100px;
  left: 130px;
  background-color: #131c21;
  z-index: 10;
  border: 1px solid #2d2e38;
  border-radius: 6px;
}

.menu-content-krest span {
  color: white;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  text-align: left;
}

.uley-line {
  height: 10px;
  position: absolute;
  top: 65px;

  border-right: 1px solid #2d2e38;
  border-left: 1px solid #2d2e38;
  border-top: 1px solid #2d2e38;
  padding: 0;
}

.uley_add_user {
  height: 40px;
  width: 40px;
  align-self: center;
  background-color: #0000 !important;
  border: 1px solid #2d2e38 !important;
  border-radius: 6px !important;
  color: #2d2e38;
  font-size: 26px;
  cursor: pointer;
  text-align: center;
  vertical-align: text-top;
  position: relative;
  margin-left: -15px;
}

.title-label {
  color: #777777;
}

.parent-element {
  text-align: center;
}

.hidden-element {
  visibility: hidden;
}

.parent-element:hover .hidden-element {
  visibility: visible;
}

.list-unstyled ul li:hover a {
  background-color: #413c40;
  color: white;
}

// Here you can add other styles

.uley_react_select {
  margin-right: 10px;
}

.custom-select_1 {
  &__control {
    background-color: transparent !important;
    border: 1px solid hsla(0, 0%, 100%, 0.09) !important;
    border-radius: 0.375rem !important;
    width: 115px !important;
    text-align: center !important;
  }
  &__single-value {
    color: #e4e5e5 !important;
  }

  &__indicators {
    display: none !important;
  }
  &__menu {
    background-color: #1f282c !important;
    width: 115px !important;
    text-align: center !important;
  }
  &__option {
    cursor: pointer !important;
    &--is-focused {
      background-color: lighten(#1f282c, 10%) !important;
    }
  }
}

.custom-select_2 {
  &__container {
    margin-right: 10px;
  }
  &__control {
    background-color: transparent !important;
    border: 1px solid hsla(0, 0%, 100%, 0.09) !important;
    border-radius: 0.375rem !important;
    width: 189px !important;
  }
  &__single-value {
    color: #e4e5e5 !important;
  }

  &__indicators {
    display: none !important;
  }
  &__menu {
    background-color: #1f282c !important;
    margin: auto;
    width: 189px !important;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  &__menu-list {
    background-color: #1f282c !important;
    text-align: center;
    width: 189px !important;
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  &__option {
    cursor: pointer !important;
    &--is-focused {
      background-color: lighten(#1f282c, 10%) !important;
    }
  }
}

.custom-select_3 {
  &__control {
    background-color: transparent !important;
    border: 1px solid hsla(0, 0%, 100%, 0.09) !important;
    border-radius: 0.375rem !important;
    width: 115px !important;
    text-align: center !important;
  }
  &__single-value {
    color: #e4e5e5 !important;
  }

  &__indicators {
    display: none !important;
  }
  &__menu {
    background-color: #1f282c !important;
    width: 115px !important;
    text-align: center !important;
  }
  &__option {
    cursor: pointer !important;
    &--is-focused {
      background-color: lighten(#1f282c, 10%) !important;
    }
  }
}
.uley_select_reset {
  color: white;
  cursor: pointer;
  // width: 25px;
  // height: 25px;
  align-self: center;
  text-align: center;
  vertical-align: text-top;
  background-color: transparent !important;
  // border: 1px solid hsla(0, 0%, 100%, 0.09) !important;
  border: 1px solid #2d2e38 !important;

  border-radius: 0.375rem !important;
}
.uley_select_reset:focus {
  outline: 0;
  box-shadow: none;
  opacity: none;
}
.uley_select_reset_el {
  margin: 0 auto;
  font-size: 18px;
}
.uley_select_reset:hover {
  box-shadow: 0 0 0 1px #2684ff;
  outline: 0;
}

.uley-smeta-generate-line {
  border-left: 1px solid rgb(255, 255, 255);
  border-top: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  padding: 0;
}
.react-datepicker-wrapper {
  width: 120px !important;
}
.uley-datepicker-control {
  background-color: transparent !important;
  border: 1px solid hsla(0, 0%, 100%, 0.09) !important;

  border-radius: 0.375rem !important;
  width: 115px;
  color: #e4e5e5;
  height: 38px;
  text-align: center !important;
}
.uley-datepicker-control:hover {
  box-shadow: 0 0 0 1px #2684ff;
  outline: 0;
}

.uley-datepicker-control:focus {
  outline: 0;
  box-shadow: 0 0 0 1px #2684ff;
  opacity: none;
}

.uley-data-main {
  color: white;

  // display: flex;
  // align-items: center;
  // width: 40px;
  // height: 40px;

  text-align: center;
  vertical-align: text-top;
  background-color: transparent !important;
  // border: 1px solid hsla(0, 0%, 100%, 0.09) !important;
  border: 1px solid #2d2e38 !important;

  border-radius: 0.375rem !important;
  overflow: hidden;
}

.uley-data-main:focus {
  outline: 0;
  box-shadow: none;
  opacity: none;
}
// .uley_select_reset_el {
//   margin: 0 auto;
//   font-size: 18px;
// }
.uley-data-main:hover {
  box-shadow: 0 0 0 1px #2684ff;
  outline: 0;
}
.uley-li:before {
  content: '—';
  position: relative;
  left: -5px;
}
.uley-tag {
  display: inline-block;
  border-radius: 2px;
  padding: 2px;
  margin: 2px;
}
/* Dropdown Button */
.dropbtn {
  /* background-color: #3498db; */
  /* color: white; */
  /* padding: 16px;
  font-size: 16px;
  border: none; */
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  /* background-color: #2980b9; */
}

/* The container <div> - needed to position the dropdown content */
/* .dropdown {
  position: relative;
  display: inline-block;
} */

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  // right: 0;
  background-color: #2b3338;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: var(--cui-border-radius);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: white;
  padding: 5px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content li:hover {
  background-color: lighten(#2b3338, 10%);
  border-radius: var(--cui-border-radius);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.popover {
  line-height: 0.5;
  width: 190px;
  text-align: center;
}

.uley-help-table {
  position: absolute;
  /* z-index: 1; */
  /* left: 545px; */
  top: 12px;
  display: none;

  border-radius: 0.375rem !important;
}

.uley-help-table-viseble {
  position: absolute;
  /* z-index: 1; */

  /* left: 545px; */
  top: 12px;

  box-shadow: inset 0 0 0 5px #2684ff;
  border-radius: 0.375rem !important;
}

.uley-table-main {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
  font-weight: normal;
}

.uley-table-main th {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: medium !important;
  font-weight: normal !important;
}

.uley-hidden-cell {
  color: #2684ff !important;
  background-color: #2b3338 !important;
}

.uley-hidden-cell-inner {
  background-color: #2b3338 !important;
  text-align: center;
  border-radius: 0.375rem !important;
  border-radius: 0.375rem 0.375rem 0 0;
}

.uley-inner-arrow {
  position: absolute;
  top: 8px;
  right: -22px;
}

.uley-inner-table tbody tr:last-child td {
  border-radius: 0 0 0.375rem 0.375rem;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}

// .uley_hour_input {
//   box-shadow: inset 0 0 0 1px #2684ff !important;
//   border-radius: 0.375rem !important;
//   background-color: transparent !important;
//   color: white !important;
//   -webkit-appearance: none !important;
//   padding: 0 5px !important;
//   width: 40px !important;
// }

// .uley_hour_input_item {
//   /* background-color: transparent !important; */
//   background-color: #1f272c !important;
//   text-align: center !important;
//   border: unset !important;
//   border-style: none !important;
//   color: white !important;
//   -webkit-appearance: none !important;
//   width: 40px !important;
// }

// .table {
//   width: 0;
// }
// Popovers
// scss-docs-start popover-variables
// $popover-font-size:                 $font-size-sm !default;
// $popover-bg:                        $white !default;
// $popover-max-width:                 276px !default;
// $popover-border-width:              $border-width !default;
// $popover-border-color:              rgba($black, .2) !default;
// $popover-border-radius:             $border-radius-lg !default;
// $popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
// $popover-box-shadow:                $box-shadow !default;

// $popover-header-bg:                 shade-color($popover-bg, 6%) !default;
// $popover-header-color:              $headings-color !default;
// $popover-header-padding-y:          .5rem !default;
// $popover-header-padding-x:          $spacer !default;

// $popover-body-color:                $body-color !default;
// $popover-body-padding-y:            $spacer !default;
// $popover-body-padding-x:            $spacer !default;

// $popover-arrow-width:               1rem !default;
// $popover-arrow-height:              .5rem !default;
// $popover-arrow-color:               $popover-bg !default;

// $popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;
// scss-docs-end popover-variables
